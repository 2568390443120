@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap');

@font-face {
  font-family: "Helvetica Neue-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("static/fonts/HelveticaNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica Neue-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/HelveticaNeueRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/HelveticaNeueMed.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica Neue-Light";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/HelveticaNeue.ttf") format("truetype");
}




.screen a {

}



.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

