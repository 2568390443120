.ticker-mobil-sommer {
    flex: 1;
    height: 45px;
    min-width: 552px;
    position: relative;
    background-color: var(--black);
}

.active-badge {
    flex: 1;
    height: 45px;
    min-width: 552px;
    font-size: 32px;
    position: relative;
    background-color: #003D4C;

}



.frame-1-1 {
    align-items: center;
    /* Vertically center children */
    display: flex;
    height: 100%;
    position: relative;
    top: 0px;
    width: 100%;
}

.fjellstrand-akkurat-n {
    letter-spacing: 0;
    position: relative;
    white-space: nowrap;
}




.marquee {
    display: flex;
    animation: marquee 160s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}