.admin-buttons {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    margin-bottom:20px;
}
.badge-entry {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Adjust based on your spacing needs */
    padding-bottom: 20px; /* Adds padding to the bottom of each entry for spacing */
    border-bottom: 1px solid #ccc; /* Adds a light gray line under each entry */
}


.badge-image {
    width: 60px; /* Adjust width as needed */
    height: 60px; /* Adjust height as needed */
    object-fit: cover;
    border-radius: 30px; /* Optional: for round images */
    margin-right: 20px;
}

.criteria-fields {
    display: flex;
    justify-content: start;
    gap: 5px; /* Adjusts the space between the start and end fields */
}

.criteria-field {
    flex: 1; /* Allows each field to grow equally, adjust as necessary */
}


.badge-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.edit-button {
    /* Your existing styles */
    margin-left: 20px; /* Ensure some space between the text and the button if needed */
}


.badge-img {
  object-fit: cover;
  position: relative;
  width: 60px;
}

.admin-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border: 1px solid #FB440E;
    background-color: transparent;
    color: #FB440E;
    border-radius: 8px; 
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    outline: none;
    transition: background-color 0.2s;
    text-decoration: none;
}

.admin-button:hover {
    background-color: #FEE5D6; 
}



.admin-container {
  width: 100%;
  max-width: 375px;
  margin: 40px auto;
}

.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:5px 0;
  border-bottom: 1px solid #ddd;
}

.user-details {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
}

.edit-button {
  background-color: #FB440E;
  padding: 5px 20px;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal {
  position: relative;
  background-color: #FFF;
  outline: none;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  max-height: 90vh; /* Adjust based on your modal's design */
  display: flex;
  flex-direction: column;
    align-items: center; /* Vertically centers the modal */
    justify-content: center; /* Horizontally centers the modal */
  margin: auto;
}



.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  
    overflow-x: visible; /* Disables horizontal scrolling */
}

.avatar-placeholder {
    width: 100px; /* Adjust based on your preference */
    height: 100px; /* Adjust based on your preference */
    background-size: cover;
    background-position: center;
    border-radius: 50%; /* Gives a circular shape, optional */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 10px solid #eeeeee; 
}


.modal-form label {
  display: block;
  margin: 10px 0;
  font-size: 16px;
}

.modal-form input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.akriver-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #FB440E;
  color: #FFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.akriver-btn:hover {
  background-color: #e54747;
}

/* Input Group Container */
.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

/* Checkbox Label Styling */
.checkbox-label {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #555;
    font-size: 16px;
}

/* Checkbox Styling */
.checkbox-label input[type="checkbox"] {
    opacity: 0; 
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px; 
}

/* Custom Checkbox */
.checkbox-label .custom-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ff4500;
    border-radius: 4px;
    background-color: #fff;
    vertical-align: middle;
    margin-right: 5px;
}

/* Checked state styling */
.checkbox-label input[type="checkbox"]:checked + .custom-checkbox {
    background-color: #ff4500;
    box-shadow: inset 0 0 0 4px #fff; 
}

.feedback-success {
    background-color: #DFF0D8;
    color: #3C763D;
    border: 1px solid #D6E9C6;
    padding: 10px;
    border-radius: 4px;
}

.feedback-error {
    background-color: #F2DEDE;
    color: #A94442;
    border: 1px solid #EBCCD1;
    padding: 10px;
    border-radius: 4px;
}

.feedback-info {
    background-color: #D9EDF7;
    color: #31708F;
    border: 1px solid #BCE8F1;
    padding: 10px;
    border-radius: 4px;
}

