.filter-year {
  align-items: left;
  display: flex;
  gap: 6px;
  padding: 0px 0px 0px 15px;
  position: relative;
  width: 375px;
}

.inaktiv-knapp {
  background-color: transparent;
  border: 1px solid #8B8585;
  border-radius: 6px;
  line-height: 0px;
  position: relative;
  width: fit-content;
  min-width: 42px;
  min-height: 24px;
  color: #8B8585; 
  cursor: pointer; 
}

.number {
  letter-spacing: 0;
  margin-top: 25px;
  margin-left: 15px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.aktiv-knapp{
  background-color: var(--vermilion);
  margin-left: 0px;
  border-radius: 6px;
  line-height: 0px;
  gap: 10px;
  padding: 3px 8px;
  position: relative;
  width: fit-content;
  min-width: 42px;
  min-height: 24;
}

