body {
    background-color: #f2f2f2 !important;
    color: var(--black);
    font-family: var(--font-family-helvetica_neue-regular);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
  height: 100%;
  margin: 0;

}

.button-group{
  width:100%;
  text-align: center;
}

.admin-table {
    width: 375px;
    margin: 5px;
    border-collapse: collapse;
    border-bottom: 1px solid #ddd;
      table-layout: fixed;
    /* Add a border at the bottom of the table */
}

.admin-table td input {
  padding: 8px 8px; /* Adjust as needed */
  box-sizing: border-box; /* This ensures padding and borders are included in the total width/height */
}

.admin-table th,
.admin-table td {
    border: none;
    /* Remove all default borders */
    padding: 15px 0 15px 0px;
    text-align: left;
}

.admin-table tr {
    border-bottom: 1px solid #ddd;
    /* Add a horizontal border for each row */
}

.admin-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.or-section {
  display: flex;
  align-items: center;
  margin: 20px 0;

}

.or-section::before,
.or-section::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: #e0e0e0;
}

.or-section span {
  margin: 0 10px;
  font-size: 14px;
  color: #757575;
}

.edit-button,
.add-button,
.save-button {
    cursor: pointer;
    background-color: #FB440E;
    /* Orange color from the image */
    color: white;
    border: none;
    padding: 6px 12px;
    /* Reduced padding for edit button */
    border-radius: 8px;
    font-size: 0.9em;
    /* Reduce font size a bit */
}

button {
    background-color: #FB440E;
    /* Orange color from the image */
    color: white;
    border: none;
    padding: 5px 20px;
    /* More padding for the bigger buttons */
    border-radius: 8px;
    font-size: 1em;
    margin-right: 10px;
    margin: 20px;
    /* Space between buttons */
    cursor: pointer;
}

button:hover,
.edit-button:hover,
.save-button:hover {
    background-color: #e54b45;
    /* Slightly darker shade for hover effect */
}

.number-input {
    width: 45px;
    /* Adjust as needed */
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f7f7f7;
    /* Light gray background for better distinction */
}

.datetime-input {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f7f7f7;
}

input[type="datetime-local"] {
    font-size: 0.9em;
    /* Adjust as necessary */
    font-family: var(--font-family-helvetica_neue-regular);
    /* Adjust as necessary */
    margin: 0;
    width: 130px;
    /* Adjust as necessary */
}

.timebadge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Takes full viewport height */
  padding: 20px;     /* Add some padding to avoid sticking content to edges */
}


.admin-table th:nth-child(1) {
  width:30px;  /* Adjust as needed */
}
.admin-table th:nth-child(2), .admin-table td:nth-child(2) {
  width: 50px;  /* Adjust as needed */
}
.admin-table th:nth-child(3), .admin-table td:nth-child(3) {
  width: 50px;  /* Adjust as needed */
}
.admin-table th:nth-child(4), .admin-table td:nth-child(4) {
  width: 130px; /* Adjust as needed */
}
.admin-table th:nth-child(5), .admin-table td:nth-child(5) {
  width: 60px;  /* Adjust as needed */
}

/* For Firefox */
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Chrome, Safari, and newer versions of Opera */
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Internet Explorer */
.number-input::-ms-clear {
  display: none;
}

.admin-buttons {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    margin-bottom:20px;
}

.admin-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border: 1px solid #FB440E;
    background-color: transparent;
    color: #FB440E;
    border-radius: 8px; 
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    outline: none;
    transition: background-color 0.2s;
    text-decoration: none;
}

.admin-button:hover {
    background-color: #FEE5D6; 
}


