.x404 {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border: 0px none;
  display: flex;
  flex-direction: column;
  padding: 50px 0px 0px;
  position: relative;
  width: 100%;      /* ensure it spans the full width */
  height: 100vh;    /* full height of the viewport */
}

.aiaiai-krvel-velle {
  height: 68px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 317px;
}

.span1 {
  color: var(--white);
}

.cta-button {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.til-hovedsiden {
  align-self: stretch;
  color: var(--white);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: 550px;
  position: relative;
  text-align: center;
  text-decoration: none;  /* removes the default underline for anchor links */
}

.x404 :hover {
    text-decoration: underline; /* adds underline on hover */
    color: var(--white);   
    text-color:white;
}

.x404 :visited {
    color: var(--white);   
    text-decoration: underline; /* underlined text for visited links */
}


