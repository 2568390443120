.record {
  position: relative;
  height: 46px;
  min-width: 375px;
}

.plassering {
  position: absolute;
  height: 45px;
  left: -10;
  top: 0;
  width: 35px;
  text-align: right;
}

.bilde {
  position: absolute;
  height: 32px;
  width: 32px;
  left: 37px;
  top: 7px;
  object-fit: cover;
  border-radius: 50%; /* Makes the image circular */
}

.name {
  position: absolute;
  height: 46px;
  left: 86px;
  top: 0;
  width: 129px;
  line-height: 16.9px;
color: black;
    text-decoration: underline;
    text-decoration-color: #fb440e;
    text-decoration-thickness: 1px;
}


.dato {
  position: absolute;
  height: 45px;
  left: 236px;
  top: 0;
  width: 51px;
  line-height: 15.6px;
}

.score {
  position: absolute;
  height: 45px;
  left: 293px;
  top: 0;
  width: 67px;
  line-height: 18.2px;
  text-align: right;
}

.skillelinje {
  position: absolute;
  bottom: 0;
  width: 100%;
}