.happyAdmin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #FB440E;
  font-family: var(--font-family-helvetica_neue-regular);
}

.spinner {
  margin: auto;
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top-color: #333;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.happyAdmin-box {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  width: 300px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.happyAdmin-box h2 {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 30px;
  text-align: center;
}

.happyAdmin-box input {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 16px;
}

.happyAdmin-box button {
  width: 100%;
  padding: 15px;
  background-color: #FB440E;
  border: none;
  margin-left:0px;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.happyAdmin-box a {
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #ff5722;
  text-decoration: none;
  cursor: pointer;
}

.or-section {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.or-section::before,
.or-section::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: #e0e0e0;
}

.or-section span {
  margin: 0 10px;
  font-size: 14px;
  color: #757575;
}
