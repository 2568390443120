@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap";
@font-face {
  font-family: Helvetica Neue-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("HelveticaNeue Bold.d1e600e3.ttf") format("truetype");
}

@font-face {
  font-family: Helvetica Neue-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("HelveticaNeueRegular.ff598533.ttf") format("truetype");
}

@font-face {
  font-family: Helvetica Neue-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("HelveticaNeueMed.d17570a1.ttf") format("truetype");
}

@font-face {
  font-family: Helvetica Neue-Light;
  font-style: normal;
  font-weight: 500;
  src: url("HelveticaNeue.7a90438f.ttf") format("truetype");
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

* {
  box-sizing: border-box;
}

:root {
  --black: #222;
  --black-2: #000;
  --vermilion: #fb440e;
  --white: #fff;
  --font-size-l: 20px;
  --font-size-l2: 24px;
  --font-size-m: 16px;
  --font-size-m1: 17px;
  --font-size-m2: 18px;
  --font-size-s: 14px;
  --font-size-xl: 50px;
  --font-size-xl1: 32px;
  --font-size-xs: 12px;
  --font-size-xxl: 64px;
  --font-size-xxs: 10px;
  --font-size-xxxs: 10px;
  --font-family-helvetica_neue-bold: "Helvetica Neue-Bold", Helvetica;
  --font-family-helvetica_neue-regular: "Helvetica Neue-Regular", Helvetica;
  --font-family-helvetica_neue-medium: "Helvetica Neue-Medium", Helvetica;
  --font-family-helvetica_neue-light: "Helvetica Neue-Light", Helvetica;
}

.helveticaneue-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-regular-white-20px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-regular-white-17px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-bold-white-17px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-regular-normal-white-10px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-regular-normal-black-10px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-regular-normal-black-xxxs {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 200;
}

.helveticaneue-regular-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-regular-normal-gray-12px {
  color: #8b8585;
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-regular-light-gray-12px {
  color: #8b8585;
  font-family: var(--font-family-helvetica_neue-light);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-bold-white-50px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-bold-white-32px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xl1);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-regular-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-black-12px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermillion-12px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-12px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-black-13px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.helvetica-bold-black-12px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermilion-10px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermilion-18px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermilion-14px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helvetica-bold-yellow-17px {
  color: #ffc917;
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helvetica-regular-normal-black-14px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helvetica-regular-normal-16px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helvetica-regular-black-16px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m2);
  font-style: normal;
  font-weight: 700;
}

.helvetica-regular-normal-14px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-24px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermilion-24px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermilion-16px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-bold-24px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 700;
}

.ticker-mobil-sommer {
  background-color: var(--black);
  flex: 1;
  min-width: 552px;
  height: 45px;
  position: relative;
}

.active-badge {
  background-color: #003d4c;
  flex: 1;
  min-width: 552px;
  height: 45px;
  font-size: 32px;
  position: relative;
}

.frame-1-1 {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  top: 0;
}

.fjellstrand-akkurat-n {
  letter-spacing: 0;
  white-space: nowrap;
  position: relative;
}

.marquee {
  animation: 160s linear infinite marquee;
  display: flex;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

body {
  background-color: #fb440e;
}

.tide-display {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  display: flex;
}

.tide-item {
  color: #fff;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.tide-arrow {
  height: 15px;
  margin-right: 8px;
}

.tide-arrow.low {
  transform: rotate(180deg);
}

.tide-time {
  font-size: 14px;
}

.tide-display-container {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.tide-title {
  letter-spacing: 0;
  min-height: 1px;
  margin-top: 19px;
  margin-bottom: 10px;
  line-height: .1px;
}

.ReactModal__Overlay {
  z-index: 100 !important;
}

.overlay-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.timebadge-overlay, .timebadge-arm, .timebadge-background {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.timebadge-background {
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.timebadge-score {
  color: #ff3a0d;
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  font-family: Noto Sans, sans-serif;
  font-size: calc(1vw + 1vh + 24px);
  font-weight: 700;
  display: flex;
  position: absolute;
  top: calc(62% - 10vh);
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (width >= 769px) {
  .timebadge-score {
    width: auto;
    height: auto;
    font-size: 36px;
    top: 32%;
    transform: translate(-50%, -50%);
  }
}

.timebadge-overlay {
  z-index: 5;
}

.timebadge-seconds {
  transform-origin: center;
  z-index: 3;
  transform-style: preserve-3d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 115%;
  height: 115%;
  animation: 60s linear infinite rotateSeconds;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timebadge-arm {
  transform-origin: center;
  z-index: 4;
  transform-style: preserve-3d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  animation: 7200s linear infinite rotateArm;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stop-animation {
  animation-play-state: paused;
}

@keyframes rotateSeconds {
  from {
    transform: translate(-50%, -50%) rotate(var(--initialRotation, 0deg));
  }

  to {
    transform: translate(-50%, -50%) rotate(calc(var(--initialRotation, 0deg)  - 360deg));
  }
}

@keyframes rotateArm {
  from {
    transform: translate(-50%, -50%) rotate(var(--initialRotation, 0deg));
  }

  to {
    transform: translate(-50%, -50%) rotate(calc(var(--initialRotation, 0deg)  - 360deg));
  }
}

.myoverlay {
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.mymodal {
  WebkitOverflowScrolling: touch;
  background: #fff;
  border: 0 solid #ccc;
  outline: none;
  padding: 40px;
  position: absolute;
  inset: 15px;
  overflow: auto;
}

.desktop-auto-layout {
  background-color: var(--white);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame-6 {
  background-color: #ff7815;
  flex-shrink: 0;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  height: 30vh;
  display: flex;
  position: relative;
}

.frame-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.frame-5 {
  background-position: center;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  padding: 0;
  display: flex;
  position: relative;
}

@media screen and (width >= 412px) and (width <= 767px) {
  .frame-1 {
    max-width: 430px;
    overflow: hidden;
  }
}

.fjellstrand-vel {
  position: relative;
}

.fjellstrand-vel-img {
  z-index: 6;
  width: 150px;
  position: absolute;
  top: 17px;
  left: 22px;
}

.mymodal .modal-content p {
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m);
  color: #000;
  margin-bottom: 1em;
  font-weight: 0;
  line-height: 1.5;
  overflow: visible;
}

.text-link img {
  width: 24px;
}

.text-link {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  z-index: 6;
  font-weight: 200;
  text-decoration: underline;
  position: absolute;
  top: 5px;
  right: 0;
  overflow: visible;
}

.text-link2 {
  color: #000;
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  font-weight: 200;
  text-decoration: underline;
  position: absolute;
  top: 15px;
  right: 20px;
  overflow: visible;
}

.text-link2 button {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.text-link2 button:focus {
  outline: none;
}

.text-link a:link, .text-link a:visited, .text-link a:hover {
  color: var(--white);
  text-decoration: underline;
}

.text-link button {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.text-link button:focus {
  outline: none;
}

.scroller {
  background-color: var(--black);
  flex-grow: 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.badetemperatur {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  height: 149px;
  padding: 0 21px;
  display: flex;
  position: relative;
}

.b-temp-f1 {
  align-items: center;
  gap: 14px;
  width: 375px;
  height: 149px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.sea-icon {
  min-width: 60px;
  height: 79px;
  position: relative;
}

.b-temp-f2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 47px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.badetemperatur-1 {
  color: var(--black-2);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  width: 133px;
  height: 1px;
  margin-top: -1px;
  font-weight: 400;
  line-height: .1px;
  position: relative;
}

.x195-c {
  color: var(--black-2);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
  width: 100%;
  height: 1px;
  margin-left: -5px;
  font-weight: 700;
  line-height: .1px;
  position: relative;
}

.nesten-som-syden {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  width: 362px;
  height: 1px;
  font-weight: 400;
  line-height: .1px;
  position: relative;
}

.bottom2 {
  background-color: var(--vermilion);
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.temps_-stuffs {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  width: 375px;
  padding: 30px 0 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.oppdatert {
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.oppdatert-31052023-kl-0823 {
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: 0;
  line-height: .1px;
  position: relative;
}

.strandtempt {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  width: 375px;
  margin-left: 20px;
  padding: 10px 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.flex-col {
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  min-height: 213px;
  display: flex;
  position: absolute;
  top: 0;
  left: 65px;
}

.andre-data {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 20px;
  margin-right: 0;
  display: flex;
  position: relative;
}

.info-block {
  align-items: flex-start;
  width: 33.3333%;
  margin-top: 0;
  margin-right: 8px;
  padding: 0;
  display: flex;
}

.text-group {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.strandtemperatur {
  letter-spacing: 0;
  line-height: 14px;
}

.x22-c {
  letter-spacing: 0;
  margin-top: 5px;
  line-height: 36px;
}

.tempText {
  letter-spacing: 0;
  min-height: 1px;
  margin-top: 19px;
  line-height: .1px;
}

.tempNumbers {
  letter-spacing: 0;
  width: 133px;
  min-height: 19px;
  margin-top: 25px;
  line-height: .1px;
}

.uv-indeks {
  letter-spacing: 0;
  min-height: 1px;
  margin-top: 20px;
  line-height: .1px;
}

.overlap-group {
  width: 137px;
  margin-top: 0;
  position: relative;
}

.number {
  letter-spacing: 0;
  width: 40px;
  line-height: .1px;
  position: absolute;
  top: -13px;
  left: -7px;
}

.hy {
  letter-spacing: 0;
  line-height: .1px;
  position: relative;
  top: 0;
  left: 5px;
}

.flex-col-1 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 47px;
  display: flex;
  position: absolute;
  top: 8px;
}

.temperature-half-1 {
  width: 35px;
  height: 35px;
  margin-left: 3px;
}

.tempIcons {
  width: 35px;
  height: 35px;
  margin-top: 28px;
}

.icon-brightness {
  width: 35px;
  height: 35px;
  margin-top: 20px;
  margin-left: 3px;
}

.bli-medlem {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 18px 0;
  display: flex;
  position: relative;
}

.flex-container {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  width: 262px;
  height: 50px;
  display: flex;
}

.text {
  letter-spacing: 0;
  text-align: center;
  width: 262px;
  line-height: .1px;
  position: relative;
}

.knapp {
  min-width: 182px;
  height: 45px;
  position: relative;
}

.overlap-group-1 {
  width: 180px;
  height: 30px;
  position: relative;
}

.rectangle-4 {
  background-color: #ffac01;
  width: 161px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 12px;
}

.bli-medlem-her {
  height: px;
  letter-spacing: 0;
  text-align: center;
  width: 180px;
  line-height: .1px;
  position: absolute;
  top: 20px;
  left: 0;
}

.bli-medlem-her a {
  color: #fff;
  text-decoration: none;
  color: #fff !important;
  text-decoration: none !important;
}

.siste-fra-nesodden {
  flex: uto;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 355px;
  margin-top: 10px;
  display: flex;
  position: relative;
}

.cta {
  box-sizing: border-box;
  background-color: #ffc917;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 23px;
  padding: 10px;
  display: flex;
  position: relative;
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.toppliste-link a {
  cursor: pointer;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  color: inherit;
  background: none;
  border: none;
  flex: 1;
  align-self: stretch;
  margin-top: -7px;
  padding: 0;
  line-height: 18.2px;
  text-decoration: none;
  position: relative;
}

.bildeNews {
  object-fit: contain;
  width: auto;
  height: 100%;
  position: relative;
}

.oppdatert2 {
  color: #fff;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 0. 1px;
  text-align: right;
  width: 100%;
  margin-top: -2px;
  margin-bottom: -1px;
  position: relative;
}

.sort-boks {
  background-color: #000;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  width: 100%;
  height: 86px;
  display: flex;
  position: relative;
}

.tekstDagbladet {
  color: #fff;
  letter-spacing: 0;
  width: 100%;
  height: 54px;
  padding: 5px 10px 5px 8px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.dagbladet {
  color: #ffc917;
  font-weight: 700;
}

.badebrikke-modal {
  text-align: left;
  z-index: 20;
  margin-bottom: 0;
  padding-top: 10px;
  line-height: 20px;
  text-decoration: none;
}

.modalLogo {
  margin-bottom: 10px;
}

.badebrikke-modal a {
  text-align: left;
  color: inherit;
  text-decoration: underline;
}

.badebrikke-modal a:hover {
  text-decoration: underline;
}

.badebrikke-modal a:visited {
  color: inherit;
}

.modal-content hr {
  border-top: 1px solid #d3d3d3;
  border-bottom: none;
  margin: 10px 0;
}

.top {
  background-color: var(--vermilion);
  flex-shrink: 0;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  height: 80px;
  display: flex;
  position: relative;
}

.happy-bellies-of-fjellstrand {
  align-self: flex-start;
  margin-left: 20px;
  position: relative;
}

.burger {
  margin-top: 0;
  position: absolute;
  right: -20px;
}

.filter-year {
  align-items: left;
  gap: 6px;
  width: 375px;
  padding: 0 0 0 15px;
  display: flex;
  position: relative;
}

.inaktiv-knapp {
  color: #8b8585;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #8b8585;
  border-radius: 6px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 42px;
  min-height: 24px;
  line-height: 0;
  position: relative;
}

.number {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 25px;
  margin-left: 15px;
}

.aktiv-knapp {
  background-color: var(--vermilion);
  border-radius: 6px;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 42px;
  min-height: 24px;
  margin-left: 0;
  padding: 3px 8px;
  line-height: 0;
  position: relative;
}

.flip-card {
  perspective: 1000px;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  order: 0;
  justify-content: space-between;
  align-items: flex-end;
  width: 150px;
  height: 128px;
  display: flex;
  position: relative;
}

.flip-card-inner {
  transform-style: preserve-3d;
  text-align: right;
  width: 150px;
  height: 128px;
  transition: transform .8s;
  display: flex;
  position: relative;
}

.flipped {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  backface-visibility: hidden;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 150px;
  height: 128px;
  padding: 12px;
  display: flex;
  position: absolute;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.info-container {
  flex-direction: column;
  align-items: flex-end;
  width: 150px;
  height: 128px;
  display: flex;
}

.hof_title, .points, .username {
  text-align: right;
}

.hof_title {
  margin-bottom: 6px;
  margin-left: 15px;
  line-height: 16px;
}

.points {
  text-align: right;
}

.username {
  max-width: 80px;
  margin-top: auto;
  line-height: 15px;
}

.profilepicture {
  border-radius: 50%;
  width: 41px;
  height: 41px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.sk-mobil {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  margin-left: 2px;
  padding: 0 0 10px;
  display: flex;
  position: relative;
}

.frame-621 {
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  align-items: center;
  gap: 8px;
  width: 345px;
  padding: 16px;
  display: flex;
  position: relative;
}

.iconsearch {
  min-width: 24px;
  height: 24px;
  position: relative;
}

.search-input-field {
  color: #8b8585;
  letter-spacing: 0;
  white-space: nowrap;
  width: 100%;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;
}

input.search-input-field {
  background: none;
  border: none;
  outline: none;
  flex-grow: 1;
  height: 100%;
}

.record {
  min-width: 375px;
  height: 46px;
  position: relative;
}

.plassering {
  text-align: right;
  width: 35px;
  height: 45px;
  position: absolute;
  top: 0;
  left: -10px;
}

.bilde {
  object-fit: cover;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 7px;
  left: 37px;
}

.name {
  color: #000;
  width: 129px;
  height: 46px;
  line-height: 16.9px;
  -webkit-text-decoration: underline #fb440e;
  text-decoration: underline #fb440e;
  text-decoration-thickness: 1px;
  position: absolute;
  top: 0;
  left: 86px;
}

.dato {
  width: 51px;
  height: 45px;
  line-height: 15.6px;
  position: absolute;
  top: 0;
  left: 236px;
}

.score {
  text-align: right;
  width: 67px;
  height: 45px;
  line-height: 18.2px;
  position: absolute;
  top: 0;
  left: 293px;
}

.skillelinje {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.badgeContainer {
  flex-wrap: wrap;
  width: 375px;
  display: flex;
}

.slick-slider {
  width: 375px;
  height: 131px;
  overflow: hidden;
}

.slick-track {
  display: flex;
}

.toppliste-autolayout {
  background-color: var(--white);
  border: 1px;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.breadcrumbs {
  min-width: 375px;
  height: 32px;
  position: relative;
}

.line-2 {
  width: 40px;
  height: 1px;
  position: absolute;
  top: 24px;
  left: 27px;
}

.hjem-toppliste {
  letter-spacing: 0;
  white-space: nowrap;
  height: 220px;
  line-height: 15.6px;
  position: absolute;
  top: 10px;
  left: 15px;
}

.hjem-toppliste a, .hjem-toppliste a:visited, .hjem-toppliste a:hover {
  color: #000;
  background-image: linear-gradient(to right, #fb440e, #fb440e);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 3px;
  text-decoration: none;
}

.cta-button:hover {
  cursor: pointer;
}

.h1-intro_text2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  width: 375px;
  margin-left: -25px;
  padding: 15px 0 10px 28px;
  display: flex;
  position: relative;
}

.topplisten-text {
  margin-top: 10px;
}

.title_topten2 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  padding-top: 10px;
  line-height: 31.2px;
  position: relative;
}

.span0 {
  color: var(--vermilion);
}

.header_title_black {
  color: var(--black);
}

.klikk-p-en-bruker-f {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  line-height: 18.2px;
  position: relative;
}

.top-list {
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  padding-right: 0;
  display: flex;
  position: relative;
}

.header-line {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 375px;
  display: flex;
  position: relative;
}

.group-28 {
  min-width: 364px;
  height: 13px;
  position: relative;
}

.flex-row {
  align-items: flex-start;
  min-width: 356px;
  height: 13px;
  display: flex;
}

.text-1 {
  align-items: right;
  cursor: pointer;
  padding-left: 20px;
  display: inline-flex;
}

.happy-belly {
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  margin-left: 50px;
  display: inline-flex;
}

.arrow-container {
  flex-direction: column;
  margin-left: 5px;
  display: flex;
}

.arrow-container img {
  width: 5px;
  height: 5px;
  margin-top: 1px;
}

.sort-arrow-container {
  vertical-align: middle;
  display: inline-block;
}

.sort-arrow {
  margin-left: 5px;
  display: inline-block;
}

.siste-badTopp {
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  margin-left: 70px;
  display: inline-flex;
}

.poengTopp2 {
  letter-spacing: 0;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  min-height: 13px;
  margin-left: 32px;
  line-height: 13px;
  display: inline-flex;
}

.line-5 {
  min-width: 375px;
  height: 1px;
  margin-bottom: -.8px;
  position: relative;
}

.load-more {
  float: none;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 0;
  padding: 5px 0 10px;
  display: flex;
  position: relative;
}

.button_visflere {
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 99px;
  height: 30px;
  padding: 10px;
  display: flex;
  position: relative;
}

.text {
  color: #aaa;
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  text-align: center;
  width: 227.74px;
  margin: -86.45px -74.37px -86.05px;
  font-weight: 700;
  line-height: 18.2px;
  position: relative;
}

.text-2 {
  letter-spacing: 0;
  text-align: center;
  width: 286px;
  margin-top: 10px;
  line-height: 18.2px;
  position: relative;
}

.cta-buttonTopp {
  background-color: var(--vermilion);
  align-items: center;
  width: 136px;
  height: 41px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  position: relative;
}

.kjop-badebrikke {
  text-align: center;
  padding-left: 9px;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.32c19c5c.gif") center no-repeat;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

.badge-gallery {
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: relative;
}

.badge-gallery .header {
  background-color: #0000;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 22px;
  width: 100%;
  padding: 13px 12px 0;
  display: flex;
  position: relative;
}

.badge-gallery .topp {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0 0;
  display: flex;
  position: relative;
}

.text-badge {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: calc(100% - 20px);
  margin: 10px;
}

.badge-gallery .logo {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.badge-gallery .happy-bellies-of {
  flex: none;
  margin-top: -8.02px;
  margin-bottom: -5.26px;
  position: relative;
}

.badge-gallery .lukk {
  align-items: flex-start;
  gap: 10px;
  width: 37px;
  height: 16px;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.badge-gallery .text-wrapper {
  color: #000;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -4px;
  font-family: Helvetica Neue-Light, Helvetica;
  font-size: 12px;
  font-weight: 300;
  line-height: .1px;
  text-decoration: underline;
  position: relative;
}

.badge-gallery .galleri-badges {
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px 5px 80px;
  display: flex;
  position: relative;
}

.badge-gallery .headertext {
  align-items: flex-start;
  gap: 10px;
  width: 289px;
  height: 50px;
  display: flex;
  position: relative;
}

.badge-gallery .umerkelser-mons {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 275px;
  margin-top: -1px;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.badge-gallery .span {
  color: #fb440e;
}

.badge-gallery .text-wrapper-2 {
  color: #000;
}

.badge-gallery .grid {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.badge-gallery .div {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 140px;
  height: 225px;
  padding: 0 0 5px;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 7px #00000040;
}

.badge-gallery .img {
  object-fit: cover;
  width: 120px;
  height: 118px;
  position: relative;
}

.badge-gallery .text {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.badge-gallery .title-badge {
  color: #fb440e;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 34px;
  margin-top: -1px;
  font-family: Helvetica Neue-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.badge-gallery .description {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 56px;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.badge-gallery .image-locked {
  object-fit: cover;
  width: 136px;
  height: 126px;
  position: relative;
}

.badge-gallery .title-locked {
  color: #fb440e;
  filter: blur(4px);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 34px;
  margin-top: -1px;
  font-family: Helvetica Neue-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.badge-gallery .description-locked {
  color: #acacac;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 56px;
  font-family: Helvetica Neue-Italic, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.badgeContainer {
  flex-wrap: wrap;
  width: 375px;
  display: flex;
}

.slick-slider {
  width: 375px;
  height: 131px;
  overflow: hidden;
}

.slick-track {
  display: flex;
}

.toppliste-autolayout {
  background-color: var(--white);
  border: 1px;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.breadcrumbs {
  min-width: 375px;
  height: 32px;
  position: relative;
}

.line-2 {
  width: 40px;
  height: 1px;
  position: absolute;
  top: 24px;
  left: 27px;
}

.hjem-toppliste {
  letter-spacing: 0;
  white-space: nowrap;
  height: 220px;
  line-height: 15.6px;
  position: absolute;
  top: 10px;
  left: 15px;
}

.hjem-toppliste a, .hjem-toppliste a:visited, .hjem-toppliste a:hover {
  color: #000;
  background-image: linear-gradient(to right, #fb440e, #fb440e);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 3px;
  text-decoration: none;
}

.cta-button:hover {
  cursor: pointer;
}

.h1-intro_text2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  width: 375px;
  margin-left: -25px;
  padding: 15px 0 10px 28px;
  display: flex;
  position: relative;
}

.topplisten-text {
  margin-top: 10px;
}

.title_topten2 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  padding-top: 10px;
  line-height: 31.2px;
  position: relative;
}

.span0 {
  color: var(--vermilion);
}

.header_title_black {
  color: var(--black);
}

.klikk-p-en-bruker-f {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  line-height: 18.2px;
  position: relative;
}

.top-list {
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  padding-right: 0;
  display: flex;
  position: relative;
}

.header-line {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 375px;
  display: flex;
  position: relative;
}

.group-28 {
  min-width: 364px;
  height: 13px;
  position: relative;
}

.flex-row {
  align-items: flex-start;
  min-width: 356px;
  height: 13px;
  display: flex;
}

.text-1 {
  align-items: right;
  cursor: pointer;
  padding-left: 20px;
  display: inline-flex;
}

.happy-belly {
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  margin-left: 50px;
  display: inline-flex;
}

.arrow-container {
  flex-direction: column;
  margin-left: 5px;
  display: flex;
}

.arrow-container img {
  width: 5px;
  height: 5px;
  margin-top: 1px;
}

.sort-arrow-container {
  vertical-align: middle;
  display: inline-block;
}

.sort-arrow {
  margin-left: 5px;
  display: inline-block;
}

.siste-badTopp {
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  margin-left: 70px;
  display: inline-flex;
}

.poengTopp2 {
  letter-spacing: 0;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  min-height: 13px;
  margin-left: 32px;
  line-height: 13px;
  display: inline-flex;
}

.line-5 {
  min-width: 375px;
  height: 1px;
  margin-bottom: -.8px;
  position: relative;
}

.load-more {
  float: none;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 0;
  padding: 5px 0 10px;
  display: flex;
  position: relative;
}

.button_visflere {
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 99px;
  height: 30px;
  padding: 10px;
  display: flex;
  position: relative;
}

.text {
  color: #aaa;
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  text-align: center;
  width: 227.74px;
  margin: -86.45px -74.37px -86.05px;
  font-weight: 700;
  line-height: 18.2px;
  position: relative;
}

.text-2 {
  letter-spacing: 0;
  text-align: center;
  width: 286px;
  margin-top: 10px;
  line-height: 18.2px;
  position: relative;
}

.cta-buttonTopp {
  background-color: var(--vermilion);
  align-items: center;
  width: 136px;
  height: 41px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  position: relative;
}

.kjop-badebrikke {
  text-align: center;
  padding-left: 9px;
}

.record {
  box-sizing: border-box;
  grid-template-columns: 10px 75px 56px 60px 42px 60px;
  align-items: center;
  gap: 10px;
  min-width: 375px;
  display: grid;
}

.record-details {
  background-color: #deeff0;
  border-bottom: .5px solid #ccc;
  margin-top: 0;
  padding: 10px 0 0;
  display: none;
}

.record-wrapper.expanded .record-details {
  display: block;
}

.detail-item {
  justify-content: space-between;
  margin-bottom: 5px;
  margin-left: 172px;
  margin-right: 21px;
  display: flex;
}

.detail-name {
  padding-top: 2px;
  font-family: Helvetica, sans-serif;
  font-size: 12px;
}

.detail-value {
  text-align: right;
  border-radius: 4px;
  margin-bottom: 5px;
  font-family: Helvetica, sans-serif;
  font-size: 12px;
}

.record-container.expanded .record-details {
  display: block;
}

.record-container.expanded .skillelinje {
  display: none;
}

.record > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0;
  overflow: hidden;
}

.last-bath-date {
  text-align: left;
  margin-left: 0;
}

.last-bath-time, .water-temp, .air-temp {
  text-align: left;
}

.score2 {
  text-align: right;
}

.skillelinje {
  width: 100%;
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
}

.camera-icon-small, .streak-icon-small {
  width: 10px;
}

.bruker-autolayout {
  background-color: var(--white);
  border: 0;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  min-width: 375px;
  padding-bottom: 20px;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.userWrapper {
  background-color: #f0f0f0;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
}

.one-pixel-line {
  background-color: #fb440e;
  flex-direction: column;
  align-items: center;
  width: 340px;
  height: 1px;
  margin-top: -10px;
  margin-bottom: 0;
  display: flex;
}

.masked-input {
  -webkit-text-security: disc;
  text-security: disc;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

@keyframes bounceOutDown {
  20% {
    transform: translateY(-20px);
  }

  50%, 55% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-1000px);
  }
}

.pulse-effect {
  opacity: 0;
  z-index: 0;
  background-color: #fb440e;
  border-radius: 80%;
  width: 80px;
  height: 80px;
  animation: 1.5s infinite pulse;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.8);
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%)scale(.8);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%)scale(1.4);
  }
}

.approved {
  animation: .6s forwards bounceOutDown;
}

.ok-text {
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.circle-btn.success {
  background-color: #2caf50;
  border-color: #2caf50;
}

.swim-icon.success {
  fill: #fff;
}

.incorrect-password {
  animation: .8s forwards shake;
}

.top {
  min-width: 375px;
  height: 80.27px;
  position: relative;
}

.line-2 {
  width: 47px;
  height: 1px;
  position: absolute;
  top: 112px;
  left: 84px;
}

.filterYearBadelogg {
  margin-bottom: 10px;
}

.profile-branding {
  align-items: center;
  gap: 14px;
  width: 375px;
  padding: 8px 0 15px 15px;
  display: flex;
  position: relative;
}

.flip-container {
  perspective: 1000px;
  width: 80px;
  height: 80px;
}

.flipped {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 80px;
  height: 80px;
}

.flipper {
  transform-style: preserve-3d;
  transition: all .6s;
  position: relative;
}

.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  z-index: 2;
  transform: rotateY(0);
}

.back {
  transform: rotateY(180deg);
}

.bilde2 {
  object-fit: cover;
  border-radius: 50%;
  min-width: 80px;
  height: 80px;
}

.back {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
  position: relative;
  transform: rotateY(180deg);
}

.content-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.circle-btn {
  z-index: 2;
  z-index: 1;
  background-color: #fff;
  border: 5px solid #fb440e;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  display: flex;
  position: relative;
}

.swim-icon {
  width: 40px;
  padding-bottom: 3px;
}

.pin-input-container {
  z-index: 2;
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
}

.pin-input-container input {
  text-align: center;
  background-color: #fff;
  border: 1px solid #fb440e;
  border-radius: 4px;
  justify-content: center;
  width: 80px;
  padding: 5px;
  font-size: 16px;
  display: flex;
}

.pin-input-container input:focus {
  border: 2px solid #fb440e;
  outline: none;
}

.flip-container button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
}

.frame-623 {
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 239px;
  height: 45px;
  display: flex;
  position: relative;
}

.title2 {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  font-weight: 400;
  line-height: 31.2px;
  position: relative;
}

.badebelly-siden-210622 {
  color: var(--black);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-bottom: -2px;
  line-height: 13px;
  position: relative;
}

.totals-year {
  align-items: center;
  gap: 0;
  width: 375px;
  padding: 0 0 0 18px;
  display: flex;
  position: relative;
}

.poeng-total {
  align-items: flex-start;
  gap: 5px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
  overflow: visible;
}

.streakLongest {
  gap: 0;
  width: 375px;
  padding: 0 0 10px 18px;
  display: flex;
  position: relative;
  overflow: visible;
}

.badStat {
  margin-top: -10px;
  margin-bottom: 10px;
}

.ng-2023 {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 18.2px;
  position: relative;
}

.pl-2023 {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  padding-left: 10px;
  line-height: 18.2px;
  position: relative;
}

.number2 {
  letter-spacing: 0;
  white-space: nowrap;
  text-align: left;
  margin-top: -1px;
  margin-right: 15px;
  line-height: 18.2px;
  position: relative;
  overflow: visible;
}

.text-social {
  letter-spacing: 0;
  text-align: left;
  width: 286px;
  margin-top: 10px;
  line-height: 18.2px;
  position: relative;
}

.header {
  background-color: #0000;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 375px;
  height: auto;
  padding: 0 0 10px 18px;
  display: flex;
  position: relative;
}

.headerStatistikk {
  background-color: #0000;
  flex-direction: column;
  align-items: flex-start;
  width: 375px;
  height: auto;
  padding: 20px 0 0 18px;
  display: flex;
  position: relative;
}

.badelogg {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l2);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  font-weight: 400;
  line-height: 31.2px;
  position: relative;
}

.baths-year {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.group-29 {
  min-width: 364px;
  height: 13px;
  position: relative;
}

.dato2 {
  letter-spacing: 0;
  width: 49px;
  min-height: 16px;
  margin-left: 20px;
  line-height: 13px;
}

.kl {
  letter-spacing: 0;
  width: 49px;
  min-height: 16px;
  margin-left: 36px;
  line-height: 13px;
}

.vann {
  letter-spacing: 0;
  width: 50px;
  min-height: 16px;
  margin-left: 18px;
  line-height: 13px;
}

.luft {
  letter-spacing: 0;
  width: 30px;
  min-height: 16px;
  margin-left: 21px;
  line-height: 13px;
}

.poeng {
  letter-spacing: 0;
  min-height: 16px;
  padding-left: 50px;
  line-height: 13px;
}

.line-5 {
  min-width: 375px;
  height: 1px;
  margin-top: 5px;
  position: relative;
}

.share {
  align-items: flex-start;
  width: 375px;
  padding: 0 0 0 18px;
  display: flex;
  position: relative;
}

.overlap-group2 {
  width: 158px;
  height: 33px;
  margin-top: -10px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.skrytebilde {
  justify-content: start;
  align-items: center;
  gap: 20px;
  align-content: left;
  width: 375px;
  height: 45px;
  margin-bottom: -10px;
  display: flex;
  position: relative;
}

.overlap-group-skrytebilde {
  border: 1px solid;
  border-color: var(--vermilion);
  cursor: pointer;
  border-radius: 4px;
  width: 156px;
  height: 32px;
  margin-top: -28px;
  margin-left: 18px;
  padding: 0 1px;
  display: flex;
}

.overlap-group-badges {
  border: 1px solid;
  border-color: var(--vermilion);
  cursor: pointer;
  border-radius: 4px;
  width: 156px;
  height: 32px;
  margin-top: -28px;
  margin-left: 0;
  padding: 0 1px;
  display: flex;
}

.camera-icon {
  align-self: center;
  width: 25px;
  height: 20px;
  margin-bottom: 3px;
  margin-left: 4px;
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.lag-skrytebilde {
  letter-spacing: 0;
  text-align: center;
  width: 123px;
  height: 30px;
  margin-top: -.27px;
  line-height: 18.2px;
}

.x4u585 {
  background-color: #e84822;
  border: 1px;
  align-items: flex-start;
  width: 400px;
  height: 510px;
  padding: 0;
  display: flex;
  position: absolute;
  top: -9999px;
  left: -9999px;
  overflow: hidden;
}

.overlap-group1-social {
  width: 566px;
  height: 466px;
  margin-top: 15px;
  margin-left: -187px;
  position: relative;
}

.overlap-group-social {
  width: 566px;
  height: 460px;
  position: absolute;
  top: 0;
  left: 0;
}

.flag {
  object-fit: cover;
  width: 317px;
  height: 77px;
  position: absolute;
  top: 383px;
  left: 0;
}

.userglory {
  object-fit: cover;
  box-sizing: border-box;
  filter: drop-shadow(0 0 8px #00000040);
  border: 9px solid #ffac01;
  width: 344px;
  height: 386px;
  position: absolute;
  top: 10px;
  left: 215px;
}

.rectangle-1 {
  background: linear-gradient(#fff183 0%, #f8aa19 57.29%, #fff183 74.48%);
  border: 4px solid #0000;
  border-image: linear-gradient(#ffc401, #fff183 47.4%, #ffc401 100%) 1;
  width: 289px;
  height: 131px;
  position: absolute;
  top: 293px;
  left: 241px;
  box-shadow: 0 0 4px #00000040;
}

.title3 {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 281px;
  line-height: normal;
  position: absolute;
  top: 306px;
  left: 245px;
}

.score-poeng {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 281px;
  line-height: normal;
  position: absolute;
  top: 334px;
  left: 241px;
}

.rank-plass {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 281px;
  line-height: normal;
  position: absolute;
  top: 363px;
  left: 245px;
}

.badet-sist2 {
  letter-spacing: 0;
  text-align: center;
  width: 281px;
  line-height: normal;
  position: absolute;
  top: 398px;
  left: 245px;
}

.happy-bellies-of-fjellstrand {
  top: 437px;
  left: 298px;
}

.modal-container {
  max-width: 100%;
  overflow: hidden;
}

.modal-container img, .modal-container .text-social {
  display: block;
}

.modalLogo {
  margin-bottom: 10px;
}

.x404 {
  background-position: 50%;
  background-size: cover;
  border: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 50px 0 0;
  display: flex;
  position: relative;
}

.aiaiai-krvel-velle {
  letter-spacing: 0;
  text-align: center;
  width: 317px;
  height: 68px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.span1 {
  color: var(--white);
}

.cta-button {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: inline-flex;
  position: relative;
}

.til-hovedsiden {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: 550px;
  font-weight: 400;
  line-height: 20.8px;
  text-decoration: none;
  position: relative;
}

.x404 :hover {
  color: var(--white);
  text-color: white;
  text-decoration: underline;
}

.x404 :visited {
  color: var(--white);
  text-decoration: underline;
}

.happyAdmin-container {
  font-family: var(--font-family-helvetica_neue-regular);
  background-color: #fb440e;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.spinner {
  border: 4px solid #0000001a;
  border-top-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: auto;
  animation: 1s ease-in-out infinite spin;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.happyAdmin-box {
  background-color: #fff;
  border-radius: 20px;
  width: 300px;
  padding: 40px;
  box-shadow: 0 4px 6px #0000001a;
}

.happyAdmin-box h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 30px;
}

.happyAdmin-box input {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;
  padding: 15px;
  font-size: 16px;
  display: block;
}

.happyAdmin-box button {
  color: #fff;
  cursor: pointer;
  background-color: #fb440e;
  border: none;
  border-radius: 30px;
  width: 100%;
  margin-left: 0;
  padding: 15px;
  font-size: 16px;
}

.happyAdmin-box a {
  text-align: center;
  color: #ff5722;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: none;
  display: block;
}

.or-section {
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.or-section:before, .or-section:after {
  content: "";
  background-color: #e0e0e0;
  flex: 1;
  height: 1px;
}

.or-section span {
  color: #757575;
  margin: 0 10px;
  font-size: 14px;
}

body {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  height: 100%;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  background-color: #f2f2f2 !important;
}

.button-group {
  text-align: center;
  width: 100%;
}

.admin-table {
  border-collapse: collapse;
  table-layout: fixed;
  border-bottom: 1px solid #ddd;
  width: 375px;
  margin: 5px;
}

.admin-table td input {
  box-sizing: border-box;
  padding: 8px;
}

.admin-table th, .admin-table td {
  text-align: left;
  border: none;
  padding: 15px 0;
}

.admin-table tr {
  border-bottom: 1px solid #ddd;
}

.admin-table th {
  text-align: left;
  background-color: #f2f2f2;
}

.or-section {
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.or-section:before, .or-section:after {
  content: "";
  background-color: #e0e0e0;
  flex: 1;
  height: 1px;
}

.or-section span {
  color: #757575;
  margin: 0 10px;
  font-size: 14px;
}

.edit-button, .add-button, .save-button {
  cursor: pointer;
  color: #fff;
  background-color: #fb440e;
  border: none;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: .9em;
}

button {
  color: #fff;
  cursor: pointer;
  background-color: #fb440e;
  border: none;
  border-radius: 8px;
  margin: 20px;
  padding: 5px 20px;
  font-size: 1em;
}

button:hover, .edit-button:hover, .save-button:hover {
  background-color: #e54b45;
}

.number-input {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 45px;
  padding: 5px 10px;
}

.datetime-input {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
}

input[type="datetime-local"] {
  font-size: .9em;
  font-family: var(--font-family-helvetica_neue-regular);
  width: 130px;
  margin: 0;
}

.timebadge-container {
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  display: flex;
}

.admin-table th:first-child {
  width: 30px;
}

.admin-table th:nth-child(2), .admin-table td:nth-child(2), .admin-table th:nth-child(3), .admin-table td:nth-child(3) {
  width: 50px;
}

.admin-table th:nth-child(4), .admin-table td:nth-child(4) {
  width: 130px;
}

.admin-table th:nth-child(5), .admin-table td:nth-child(5) {
  width: 60px;
}

.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input::-ms-clear {
  display: none;
}

.admin-buttons {
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
}

.admin-button {
  color: #fb440e;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #fb440e;
  border-radius: 8px;
  outline: none;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .2s;
  display: flex;
}

.admin-button:hover {
  background-color: #fee5d6;
}

.admin-buttons {
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
}

.badge-entry {
  border-bottom: 1px solid #ccc;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
}

.badge-image {
  object-fit: cover;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.criteria-fields {
  justify-content: start;
  gap: 5px;
  display: flex;
}

.criteria-field {
  flex: 1;
}

.badge-info {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.edit-button {
  margin-left: 20px;
}

.badge-img {
  object-fit: cover;
  width: 60px;
  position: relative;
}

.admin-button {
  color: #fb440e;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #fb440e;
  border-radius: 8px;
  outline: none;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .2s;
  display: flex;
}

.admin-button:hover {
  background-color: #fee5d6;
}

.admin-container {
  width: 100%;
  max-width: 375px;
  margin: 40px auto;
}

.user-item {
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  display: flex;
}

.user-details {
  align-items: center;
  display: flex;
}

.user-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.edit-button {
  color: #fff;
  cursor: pointer;
  background-color: #fb440e;
  border: none;
  border-radius: 6px;
  padding: 5px 20px;
}

.Overlay {
  background-color: #ffffffbf;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.Modal {
  background-color: #fff;
  border-radius: 10px;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  max-height: 90vh;
  margin: auto;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-content {
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow-x: visible;
}

.avatar-placeholder {
  cursor: pointer;
  background-position: center;
  background-size: cover;
  border: 10px solid #eee;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  display: flex;
}

.modal-form label {
  margin: 10px 0;
  font-size: 16px;
  display: block;
}

.modal-form input {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.akriver-btn {
  color: #fff;
  cursor: pointer;
  background-color: #fb440e;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
}

.akriver-btn:hover {
  background-color: #e54747;
}

.input-group {
  flex-direction: column;
  margin-bottom: 15px;
  display: flex;
}

.checkbox-label {
  color: #555;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.checkbox-label input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: absolute;
}

.checkbox-label .custom-checkbox {
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #ff4500;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
}

.checkbox-label input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #ff4500;
  box-shadow: inset 0 0 0 4px #fff;
}

.feedback-success {
  color: #3c763d;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
  padding: 10px;
}

.feedback-error {
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  padding: 10px;
}

.feedback-info {
  color: #31708f;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  border-radius: 4px;
  padding: 10px;
}

.admin-buttons {
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
}

.admin-button {
  color: #fb440e;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #fb440e;
  border-radius: 8px;
  outline: none;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .2s;
  display: flex;
}

.admin-button:hover {
  background-color: #fee5d6;
}

.admin-container {
  width: 100%;
  max-width: 375px;
  margin: 40px auto;
}

.user-item {
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  display: flex;
}

.user-details {
  align-items: center;
  display: flex;
}

.user-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.edit-button {
  color: #fff;
  cursor: pointer;
  background-color: #fb440e;
  border: none;
  border-radius: 6px;
  padding: 5px 20px;
}

.Overlay {
  background-color: #ffffffbf;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.Modal {
  background-color: #fff;
  border-radius: 10px;
  outline: none;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  position: relative;
}

.modal-content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.avatar-placeholder {
  background-color: #a7ccd8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  display: flex;
}

.modal-form label {
  margin: 10px 0;
  font-size: 16px;
  display: block;
}

.modal-form input {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.akriver-btn {
  color: #fff;
  cursor: pointer;
  background-color: #fb440e;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
}

.akriver-btn:hover {
  background-color: #e54747;
}

.input-group {
  flex-direction: column;
  margin-bottom: 15px;
  display: flex;
}

.checkbox-label {
  color: #555;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.checkbox-label input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: absolute;
}

.checkbox-label .custom-checkbox {
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #ff4500;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
}

.checkbox-label input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #ff4500;
  box-shadow: inset 0 0 0 4px #fff;
}

.feedback-success {
  color: #3c763d;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
  padding: 10px;
}

.feedback-error {
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  padding: 10px;
}

.feedback-info {
  color: #31708f;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  border-radius: 4px;
  padding: 10px;
}

@media screen and (orientation: landscape) {
  #game-container {
    z-index: 10;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

#game-container {
  width: 100%;
  height: 100%;
  position: relative;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}



/*# sourceMappingURL=index.14f77f8c.css.map */
