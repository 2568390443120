.admin-buttons {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    margin-bottom:20px;
}

.admin-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border: 1px solid #FB440E;
    background-color: transparent;
    color: #FB440E;
    border-radius: 8px; 
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    outline: none;
    transition: background-color 0.2s;
    text-decoration: none;
}

.admin-button:hover {
    background-color: #FEE5D6; 
}



.admin-container {
  width: 100%;
  max-width: 375px;
  margin: 40px auto;
}

.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:5px 0;
  border-bottom: 1px solid #ddd;
}

.user-details {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
}

.edit-button {
  background-color: #FB440E;
  padding: 5px 20px;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal {
  position: relative;
  background-color: #FFF;
  outline: none;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-placeholder {
  width: 150px;
  height: 150px;
  background-color: #a7ccd8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.modal-form label {
  display: block;
  margin: 10px 0;
  font-size: 16px;
}

.modal-form input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.akriver-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #FB440E;
  color: #FFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.akriver-btn:hover {
  background-color: #e54747;
}

/* Input Group Container */
.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

/* Checkbox Label Styling */
.checkbox-label {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #555;
    font-size: 16px;
}

/* Checkbox Styling */
.checkbox-label input[type="checkbox"] {
    opacity: 0; 
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px; 
}

/* Custom Checkbox */
.checkbox-label .custom-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ff4500;
    border-radius: 4px;
    background-color: #fff;
    vertical-align: middle;
    margin-right: 5px;
}

/* Checked state styling */
.checkbox-label input[type="checkbox"]:checked + .custom-checkbox {
    background-color: #ff4500;
    box-shadow: inset 0 0 0 4px #fff; 
}

.feedback-success {
    background-color: #DFF0D8;
    color: #3C763D;
    border: 1px solid #D6E9C6;
    padding: 10px;
    border-radius: 4px;
}

.feedback-error {
    background-color: #F2DEDE;
    color: #A94442;
    border: 1px solid #EBCCD1;
    padding: 10px;
    border-radius: 4px;
}

.feedback-info {
    background-color: #D9EDF7;
    color: #31708F;
    border: 1px solid #BCE8F1;
    padding: 10px;
    border-radius: 4px;
}

