
.title_topten{

}

.badgeContainer {
    display: flex;
    flex-wrap: wrap;
    width:375px;  
}

.slick-slider {
  width: 375px; 
  height:131px;
  overflow: hidden;
}
.slick-track {
  display: flex;
}


 .toppliste-autolayout {
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width:100%;
  position: relative;
  height: auto;
}


.breadcrumbs {
  height: 32px;
  min-width: 375px;
  position: relative;
}

.line-2 {
  height: 1px;
  left: 27px;
  position: absolute;
  top: 24px;
  width: 40px;
}



.hjem-toppliste {
    height: 220px;
    left: 15px;
    letter-spacing: 0;
    line-height: 15.6px;
    position: absolute;
    top: 10px;
    white-space: nowrap;
}

.hjem-toppliste a,
.hjem-toppliste a:visited,
.hjem-toppliste a:hover {
  color: black;
  text-decoration: none; /* Turn off the default underline */
  background-repeat: no-repeat;
  background-size: 100% 1px; /* width and height of underline */
  background-position: 0% 100%; /* horizontal and vertical position */
  
  /* This line creates the underline effect */
  background-image: linear-gradient(to right, #fb440e, #fb440e); 
  padding-bottom: 3px; /* Control the distance of the underline from the text */
}

.cta-button:hover {
    cursor: pointer;
}

.h1-intro_text2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 15px 0px 10px 28px;
  position: relative;
  margin-left: -25px;
  width: 375px;
}

.topplisten-text{margin-top:10px}

.title_topten2 {
  align-self: stretch;
  color: transparent;
  padding-top:10px;
  letter-spacing: 0;
  line-height: 31.2px;
  position: relative;
  white-space: nowrap;
}

.span0 {
  color: var(--vermilion);
}

.header_title_black {
  color: var(--black);
}

.klikk-p-en-bruker-f {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
  white-space: nowrap;
}

.top-list {
  align-items: center;
  align-self: stretch;
  display: flex;
  padding-right:0px;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
}

.header-line {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: 375px;
  
}

.group-28 {
  height: 13px;
  min-width: 364px;
  position: relative;
}

.flex-row {
  align-items: flex-start;
  display: flex;
  height: 13px;
  min-width: 356px;
}

.text-1 {
  padding-left:20px;
  display: inline-flex;
  align-items: right;
  cursor: pointer; 
}

.happy-belly {
    display: inline-flex;
    align-items: center;
    cursor: pointer; 
    margin-left:50px;
    white-space: nowrap;
}
.arrow-container {
    display: flex;
    flex-direction: column;
    margin-left: 5px; 
}

.arrow-container img {
    width: 5px; 
    height: 5px; 
    margin-top: 1px; 
}

.sort-arrow-container {
    display: inline-block;
    vertical-align: middle;
}

.sort-arrow {
    display: inline-block;
    margin-left: 5px;
}


.siste-badTopp {
 margin-left:70px;
  display: inline-flex;
  align-items: center;
  cursor: pointer; 
  white-space: nowrap;
}

.poengTopp2 {
  letter-spacing: 0;
  line-height: 13.0px;
  margin-left: 32px;
  min-height: 13px;
  white-space: nowrap;
    display: inline-flex;
  align-items: center;
  cursor: pointer; 
}

.line-5 {
  height: 1px;
  margin-bottom: -0.80px;
  min-width: 375px;
  position: relative;
}

.load-more {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px 10px;
  position: relative; 
  width: 100%;
  float: none;  
   margin-top: 0px;
}


.button_visflere {
  align-items: center;
  border: 1px solid;
  border-color: #aaaaaa;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 30px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 99px;
  cursor: pointer; 
}

.text {
  color: #aaaaaa;
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18.2px;
  margin-bottom: -86.05px;
  margin-left: -74.37px;
  margin-right: -74.37px;
  margin-top: -86.45px;
  position: relative;
  text-align: center;
  width: 227.74px;
}

.text-2 {
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: 10px;
  position: relative;
  text-align: center;
  width: 286px;
}

.cta-buttonTopp {
  align-items: center;
  background-color: var(--vermilion);
  display: flex;
  padding: 10px;
  position: relative;
  width: 136px;
  height:41px;  
  margin-top:5px;
  margin-bottom:10px;
}

.kjop-badebrikke {
  text-align: center;
  padding-left: 9px;
}

