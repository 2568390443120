.record {
  display: grid;
  grid-template-columns: 10px 75px 56px 60px 42px 60px; /* adjust these widths as needed */
  gap: 10px; /* adjust this for spacing between columns */
  align-items: center;
  min-width: 375px;
  box-sizing: border-box;
}

/* Wrapper around each record that includes the details */
.record-details {
  display: none; /* Hidden by default, shown when parent has `.expanded` class */
  background-color: #DEEFF0;
  padding: 0px;
  border-bottom: 0.5px solid #ccc;
  margin-top:0px;
  padding-top:10px;
}

.record-wrapper.expanded .record-details {
  display: block; /* Show the details when expanded */
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin-left: 172px;
  margin-right: 21px  ;
  margin-bottom: 5px;
} 

.detail-name {
  /* Style for detail name */
  font-family: 'Helvetica', sans-serif;
  font-size: 12px; /* Example font size */
  padding-top:2px;
}

.detail-value {
  /* Style for detail value */
  font-family: 'Helvetica', sans-serif;
  font-size: 12px; /* Example font size */
  text-align: right;
  border-radius: 4px;
  margin-bottom: 5px;
}

.record-container.expanded .record-details {
  display: block;
}
.record-container.expanded .skillelinje {
  display: none; /* Hide the skillelinje when expanded */
}


/* Common styles for all columns */
.record > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0;
}

/* You can retain your individual classes for additional styles or specificity if needed, for instance: */
.last-bath-date {
  text-align: left;
  margin-left:0px;
}

.last-bath-time {
  text-align: left;
}

.water-temp {
  text-align: left;
}

.air-temp {
  text-align: left;
}

.score2 {
  text-align: right;
}

.skillelinje {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
}

.camera-icon-small{

  width:10px;
}
.streak-icon-small{

  width:10px;
}