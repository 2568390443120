body {
    background-color: #fb440e;
}

.tide-display {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 0px 0px 0px 0px;
  width: 100%; 
}

.tide-item {
  display: flex;
  align-items: center;
  color: #fff;
  flex-grow: 1; 
}

.tide-arrow {
  
  height: 15px;
  margin-right: 8px; 
}

.tide-arrow.low {
  transform: rotate(180deg); 
}

.tide-time {
  font-size: 14px;
}
.tide-display-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  width: 100%; 
}

.tide-title {
 letter-spacing: 0;
    line-height: 0.1px;
    margin-top: 19px;
    margin-bottom: 10px;
    min-height: 1px;
}





.ReactModal__Overlay {
    z-index: 100 !important;
}

.overlay-content {
    position: relative;
    width: 100%;
    height: 100%;
}

.timebadge-overlay,
.timebadge-arm,
.timebadge-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.timebadge-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Set the width to 100% */
    height: 100%;
    /* Set the height to 100% */
    object-fit: cover;
    z-index: 1;
}

/* Default styles for screens smaller than 768px (e.g., iPhone) */
.timebadge-score {
    position: absolute;
    display: flex;
    /* Use flexbox for centering */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */

    font-family: 'Noto Sans', sans-serif;
    font-weight: 700;
    font-size: calc(1vw + 1vh + 24px);
    /* Adjust the formula for desired scaling */
    color: #ff3a0d;
    transform: translate(-50%, -50%);
    z-index: 2;
    top: calc(62% - 10vh);
    /* Adjust the top positioning as needed */
    left: 50%;
    width: 50%;
    height: 50%;
}

/* Media query for screens larger than 768px (e.g., desktop) */
@media screen and (min-width: 769px) {
    .timebadge-score {
        font-size: 36px;
        /* Adjust the font size for desktop */
        top: 32%;
        /* Adjust the top positioning for desktop */
        transform: translate(-50%, -50%);
        width: auto;
        /* Adjust width and height as needed for desktop */
        height: auto;
    }
}




.timebadge-overlay {
    z-index: 5;
}


.timebadge-seconds {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 115%;
    height: 115%;
    transform-origin: center;
    transform: translate(-50%, -50%);
    z-index: 3;
    animation: rotateSeconds 60s linear infinite;
    transform-style: preserve-3d;

    background-size: contain;
    /* Adjust this property as needed */
    background-repeat: no-repeat;
    background-position: center center;
    /* Center the background image */
}

.timebadge-arm {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform: translate(-50%, -50%);
    z-index: 4;
    animation: rotateArm 7200s linear infinite;
    transform-style: preserve-3d;

    background-size: contain;
    /* Adjust this property as needed */
    background-repeat: no-repeat;
    background-position: center center;
    /* Center the background image */
}

.stop-animation {
    animation-play-state: paused;
}



@keyframes rotateSeconds {
    from {
        transform: translate(-50%, -50%) rotate(var(--initialRotation, 0deg));
    }

    to {
        transform: translate(-50%, -50%) rotate(calc(var(--initialRotation, 0deg) - 360deg));
        /* Clockwise rotation */
    }
}

@keyframes rotateArm {
    from {
        transform: translate(-50%, -50%) rotate(var(--initialRotation, 0deg));
    }

    to {
        transform: translate(-50%, -50%) rotate(calc(var(--initialRotation, 0deg) - 360deg));
        /* Counterclockwise rotation */
    }
}


.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mymodal {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 0px solid #ccc;
    background: #fff;
    overflow: auto;
    WebkitOverflowScrolling: touch;
    outline: none;
    padding: 40px;
}


.desktop-auto-layout {
    align-items: center;
    background-color: var(--white);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    overflow: hidden;
}


.frame-6 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #ff7815;
    display: flex;
    height: 30vh;
    justify-content: center;
    flex-shrink: 0;
    /* Added */
    position: relative;
}

.frame-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
}




.frame-5 {
    align-items: flex-start;
    align-self: stretch;
    background-position: center;
    background-size: cover;
    display: flex;
    flex: 1;
    padding: 0;
    position: relative;
}

@media screen and (min-width: 412px) and (max-width: 767px) { 
    .frame-1 {
        max-width: 430px; 
        overflow: hidden;
    }
}


.fjellstrand-vel {
    position: relative;
}

.fjellstrand-vel-img {
    position: absolute;
    top: 17;
    left: 22;
    width: 150px;
    /* or set a fixed width and height */
    z-index: 6;
}

.mymodal .modal-content p {
    font-family: var(--font-family-helvetica_neue-regular);
    font-size: var(--font-size-m);
    font-weight: 0;
    line-height: 1.5;
    margin-bottom: 1em;
    overflow: visible;
    color: black;
}

.text-link img {
    width: 24px;


}

.text-link {
    position: absolute;
    right: 0px;
    top:5px;
    color: var(--white);
    font-family: var(--font-family-helvetica_neue-medium);
    font-size: var(--font-size-xs);
    font-weight: 200;
    overflow: visible;
    text-decoration: underline;
    z-index: 6;

}

.text-link2 {
    position: absolute;
    right: 20px;
    top: 15px;
    color: black;
    font-family: var(--font-family-helvetica_neue-medium);
    font-size: var(--font-size-xs);
    font-weight: 200;
    overflow: visible;
    text-decoration: underline;

}

.text-link2 button {
    background: none;
    border: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
    text-decoration: underline;

}

.text-link2 button:focus {
    outline: none;
}


.text-link a:link,
.text-link a:visited {
    color: var(--white);
    text-decoration: underline;
}

.text-link a:hover {
    color: var(--white);
    text-decoration: underline;
}

.text-link button {
    background: none;
    border: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
}

.text-link button:focus {
    outline: none;
}



.scroller {
    align-items: center;
    align-self: stretch;
    background-color: var(--black);
    display: flex;
    gap: 0px;
    justify-content: center;
    padding: 0px;
    position: relative;
    flex-grow: 0;
    /* Set flex-grow to 0 to prevent it from expanding */
    overflow: hidden;
}


.badetemperatur {
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 149px;
    justify-content: center;
    padding: 0px 21px;
    position: relative;
}

.b-temp-f1 {
    align-items: center;
    display: flex;
    gap: 14px;
    height: 149px;
    overflow: hidden;
    position: relative;
    width: 375px;
}

.sea-icon {
    height: 79px;
    min-width: 60px;
    position: relative;
}

.b-temp-f2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 47px;
    padding: 10px 0px;
    position: relative;
    width: fit-content;
}

.badetemperatur-1 {
    color: var(--black-2);
    font-family: var(--font-family-helvetica_neue-medium);
    font-size: var(--font-size-xs);
    font-weight: 400;
    height: 1px;
    letter-spacing: 0;
    line-height: 0.1px;
    margin-top: -1.00px;
    position: relative;
    width: 133px;
}

.x195-c {
    color: var(--black-2);
    font-family: var(--font-family-helvetica_neue-bold);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    height: 1px;
    letter-spacing: 0;
    line-height: 0.1px;
    position: relative;
    margin-left: -5px;
    width: 100%;
}

.nesten-som-syden {
    color: var(--vermilion);
    font-family: var(--font-family-helvetica_neue-medium);
    font-size: var(--font-size-l);
    font-weight: 400;
    height: 1px;
    letter-spacing: 0;
    line-height: 0.1px;
    position: relative;
    width: 362px;
}

.bottom2 {
    align-items: center;
    align-self: stretch;
    background-color: var(--vermilion);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    overflow: hidden;
}

.temps_-stuffs {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0px;
    overflow: hidden;
    padding: 30px 0px 0px;
    position: relative;
    width: 375px;
}

.oppdatert {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 0px;
    justify-content: flex-end;
    padding-top: 20px;
    padding-bottom: 0px;
    position: relative;
    overflow: visible;
    width: 100%;
}

.oppdatert-31052023-kl-0823 {
    letter-spacing: 0;
    line-height: 0.1px;
    margin-top: -1.00px;
    position: relative;
    margin-right: 0px;
    text-align: right;
    width: fit-content;
}

.strandtempt {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0px;
    overflow: visible;
    padding: 10px 0px;
    position: relative;
    margin-left: 20px;
    width: 375px;
}

.flex-col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 65px;
    min-height: 213px;
    position: absolute;
    top: 0;
    width: 300px;
}

.andre-data {
    margin-right: 0px;
    min-width: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}


.info-block {
    display: flex;
    align-items: flex-start;
    margin-right: 8px;
    margin-top: 0px;
    padding: 0px;
    width: calc(100% / 3);
}


.text-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.strandtemperatur {
    letter-spacing: 0;
    line-height: 14px;
    /* Adjust as needed */
}

.x22-c {
    letter-spacing: 0;
    line-height: 36px;
    /* Adjust as needed */
    margin-top: 5px;
    /* Adjust as needed */
}

.tempText {
    letter-spacing: 0;
    line-height: 0.1px;
    margin-top: 19px;
    min-height: 1px;
}

.tempNumbers {
    letter-spacing: 0;
    line-height: 0.1px;
    margin-top: 25px;
    min-height: 19px;
    width: 133px;
}

.uv-indeks {
    letter-spacing: 0;
    line-height: 0.1px;
    margin-top: 20px;
    min-height: 1px;
}

.overlap-group {
    margin-top: 0px;
    position: relative;
    width: 137px;
}

.number {
    left: -7;
    letter-spacing: 0;
    line-height: 0.1px;
    position: absolute;
    top: -13;
    width: 40px;
}

.hy {
    left: 5px;
    letter-spacing: 0;
    line-height: 0.1px;
    position: relative;
    top: 0px;
}

.flex-col-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 8px;
    width: 47px;
}

.temperature-half-1 {
    height: 35px;
    margin-left: 3px;
    width: 35px;
}

.tempIcons {
    height: 35px;
    margin-top: 28px;
    width: 35px;
}

.icon-brightness {
    height: 35px;
    margin-left: 3px;
    margin-top: 20px;
    width: 35px;
}

.bli-medlem {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px 0px;
    position: relative;
}

.flex-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 50px;
    width: 262px;
}

.text {
    letter-spacing: 0;
    line-height: 0.1px;
    position: relative;
    text-align: center;
    width: 262px;
}

.knapp {
    height: 45px;
    min-width: 182px;
    position: relative;
}

.overlap-group-1 {
    height: 30px;
    position: relative;
    width: 180px;
}

.rectangle-4 {
    background-color: #ffac01;
    height: 45px;
    left: 12px;
    position: absolute;
    top: 0;
    width: 161px;
}

.bli-medlem-her {
    height: px;
    left: 0;
    letter-spacing: 0;
    line-height: 0.1px;
    position: absolute;
    text-align: center;
    top: 20px;
    width: 180px;
}

.bli-medlem-her a {
    color: white;
    text-decoration: none;
}

.bli-medlem-her a {
    color: white !important;
    text-decoration: none !important;
}


.siste-fra-nesodden {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: uto;
    flex-direction: column;
    position: relative;
    width: 355px;
    margin-top: 10px;
}

.cta {
    align-items: center;
    box-sizing: border-box;
    align-self: stretch;
    background-color: #FFC917;
    display: flex;
    gap: 10px;
    height: 23px;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 100%;
}

.valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.toppliste-link a{
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    align-self: stretch;
    flex: 1;
    letter-spacing: 0;
    line-height: 18.2px;
    margin-top: -7px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    color: inherit;
    text-decoration: none;  
}


.bildeNews {
    height: 100%;
    object-fit: contain;
    position: relative;
    width: auto;
}



.oppdatert2 {
    color: white;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 0. 1px;
    margin-bottom: -1px;
    margin-top: -2px;
    position: relative;
    text-align: right;
    width: 100%
}


.sort-boks {
    align-items: center;
    align-self: stretch;
    background-color: black;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
    width: 100%;
    height: 86px;
}

.tekstDagbladet {
    color: white;
    font-weight: 400;
    height: 54px;
    letter-spacing: 0;
    line-height: 21px;
    position: relative;
    padding: 5px 10px 5px 8px;
    width: 100%;
}

.dagbladet {
    color: #FFC917;
    font-weight: 700;
}