:root { 
  --black: #222222;
  --black-2: #000000;
  --vermilion: #fb440e;
  --white: #ffffff;
 
  --font-size-l: 20px;
  --font-size-l2: 24px;
  --font-size-m: 16px;
  --font-size-m1: 17px;
  --font-size-m2: 18px;
  --font-size-s: 14px;
  --font-size-xl: 50px;
  --font-size-xl1: 32px;
  --font-size-xs: 12px;
  --font-size-xxl: 64px;
  --font-size-xxs: 10px;
  --font-size-xxxs: 10px;
 
  --font-family-helvetica_neue-bold: "Helvetica Neue-Bold", Helvetica;
  --font-family-helvetica_neue-regular: "Helvetica Neue-Regular", Helvetica;
  --font-family-helvetica_neue-medium: "Helvetica Neue-Medium", Helvetica;
  --font-family-helvetica_neue-light: "Helvetica Neue-Light", Helvetica;
}
.helveticaneue-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-regular-white-20px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight:700;
}

.helveticaneue-regular-white-17px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight:400;
}

.helveticaneue-bold-white-17px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight:700;
}




.helveticaneue-regular-normal-white-10px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}
.helveticaneue-regular-normal-black-10px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-regular-normal-black-xxxs {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 200;
}

.helveticaneue-regular-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-regular-normal-gray-12px {
  color: #8B8585;
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helveticaneue-regular-light-gray-12px {
  color: #8B8585;
  font-family: var(--font-family-helvetica_neue-light);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}



.helveticaneue-bold-white-50px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-bold-white-32px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xl1);
  font-style: normal;
  font-weight: 700;
}

.helveticaneue-regular-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-black-12px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermillion-12px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}
.helvetica-regular-normal-12px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}


.helvetica-regular-normal-black-13px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.helvetica-bold-black-12px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}


.helvetica-regular-normal-vermilion-10px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermilion-18px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-vermilion-14px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helvetica-bold-yellow-17px {
  color: #FFC917;
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helvetica-regular-normal-black-14px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.helvetica-regular-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.helvetica-regular-normal-16px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.helvetica-regular-black-16px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m2);
  font-style: normal;
  font-weight: 700;
}


.helvetica-regular-normal-14px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-normal-24px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}
.helvetica-regular-normal-vermilion-24px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}
.helvetica-regular-normal-vermilion-16px {
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.helvetica-regular-bold-24px {
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-bold);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 700;
}
