/* GameStyles.css */

/* Styles applied when the device is in landscape orientation */
@media screen and (orientation: landscape) {
  #game-container {
    width: 100%;  /* Take up full width */
    height: 100%; /* Take up full height */
    position: absolute; /* Position it over other elements */
    top: 0;
    left: 0;
    z-index: 10; /* Ensure it's above other content */
  }

  /* Add other landscape-specific styles here */
}
#game-container {
    width: 100%; /* or any specific size you prefer */
    height: 100%; /* or any specific size you prefer */
    position: relative; /* if you need to position elements inside it */
  }
  
  canvas {
    width: 100%;
    height: 100%;
    display: block; /* Removes extra space below the canvas */
  }
  