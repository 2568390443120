.badge-gallery {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.badge-gallery .header {
  align-items: flex-start;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 22px;
  padding: 13px 12px 0px;
  position: relative;
  width: 100%;
}

.badge-gallery .topp {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 10px 0px 0px;
  position: relative;
  width: 100%;
}

.text-badge {
    word-wrap: break-word;   
    overflow-wrap: break-word; 
    margin: 10px;            
    max-width: calc(100% - 20px);
}

.badge-gallery .logo {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.badge-gallery .happy-bellies-of {
  flex: 0 0 auto;
  margin-bottom: -5.26px;
  margin-top: -8.02px;
  position: relative;
}

.badge-gallery .lukk {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 16px;
  padding: 0px 5px;
  position: relative;
  width: 37px;
}

.badge-gallery .text-wrapper {
  color: #000000;
  font-family: "Helvetica Neue-Light", Helvetica;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 0.1px;
  margin-right: -4px;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

.badge-gallery .galleri-badges {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 20px 5px 80px;
  position: relative;
  width: 100%;
}

.badge-gallery .headertext {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 50px;
  position: relative;
  width: 289px;
}

.badge-gallery .umerkelser-mons {
  color: transparent;
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 275px;
}

.badge-gallery .span {
  color: #fb440e;
}

.badge-gallery .text-wrapper-2 {
  color: #000000;
}

.badge-gallery .grid {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 20px 20px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.badge-gallery .div {
  align-items: center;
  background-color: #ffffff;
  box-shadow: 1px 1px 7px #00000040;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 225px;
  padding: 0px 0px 5px;
  position: relative;
  width: 140px;
}

.badge-gallery .img {
  height: 118px;
  object-fit: cover;
  position: relative;
  width: 120px;
}

.badge-gallery .text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.badge-gallery .title-badge {
  align-self: stretch;
  color: #fb440e;
  font-family: "Helvetica Neue-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 34px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.badge-gallery .description {
  align-self: stretch;
  color: #000000;
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  height: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.badge-gallery .image-locked {
  height: 126px;
  object-fit: cover;
  position: relative;
  width: 136px;
}

.badge-gallery .title-locked {
  align-self: stretch;
  color: #fb440e;
  filter: blur(4px);
  font-family: "Helvetica Neue-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 34px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.badge-gallery .description-locked {
  align-self: stretch;
  color: #acacac;
  font-family: "Helvetica Neue-Italic", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  height: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}
