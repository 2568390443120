.flip-card {
    position: relative;
    width: 150px;
    height: 128px;
    border-radius: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column; 
    perspective: 1000px;
}
.flip-card-inner {
  position: relative;
 width: 150px;
height: 128px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  text-align: right;
  display: flex;
  position: relative;

}

.flipped {
  transform: rotateY(180deg);
}


.flip-card-front, .flip-card-back {
    border-radius: 20px;
    position: absolute;  /* Changed to relative */
    width: 150px;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 12px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.info-container {
    display: flex;
    width: 150px;
    height: 128px;
    flex-direction: column;
    align-items: flex-end;

}

.hof_title, .points, .username {
    text-align: right;

}

.hof_title {
    line-height: 16px;
    margin-bottom:6px;
    margin-left:15px;
}

.points {
    text-align:right;
}

.username {
    line-height: 15px;
    max-width: 80px;
    margin-top: auto; /* push it to the bottom */
}


.profilepicture {
    width: 41px;
    height: 41px;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    left: 10px;
}
