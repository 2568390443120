.sk-mobil {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 10px;
  position: relative;
  margin-left: 2px;
}

.frame-621 {
  align-items: center;
  border: 1px solid;
  border-color: #c3c3c3;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 16px;
  position: relative;
  width: 345px;
}

.iconsearch {
  height: 24px;
  min-width: 24px;
  position: relative;
}

.search-input-field {
  color: #8B8585;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

input.search-input-field{
  border: none;
  outline: none;
  flex-grow: 1;
  height: 100%;
  background: transparent;
}
