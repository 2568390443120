.top {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--vermilion);
  display: flex;
  height: 80px;
  justify-content: center;
  flex-shrink: 0; /* Added */
  position: relative;
}

.happy-bellies-of-fjellstrand {
  position: relative; 
  margin-left:20px;
  align-self: flex-start;
}

.burger {
  position: absolute;
  right: -20px;
  margin-top:0px;
 
}

