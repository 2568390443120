.bruker-autolayout {
  align-items: center;
  background-color: var(--white);
  border: 0px none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 375px;
  position: relative;
  overflow-y: auto;
  padding-bottom: 20px;
}

.userWrapper{
  align-items: center;
   display: flex;
  flex-direction: column;
  background-color: #F0F0F0;
  gap: 8px;
  width:100%;
}

.one-pixel-line {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 1px;
  background-color: #FB440E; /* color */
  width: 340px;
  margin-top:-10px;
  margin-bottom:0px;
}



.masked-input {
    -webkit-text-security: disc; /* for Safari and Chrome */
    text-security: disc; /* may be needed for other browsers in the future */
    /* Optionally, you can add more styling here */
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
    20%, 40%, 60%, 80% { transform: translateX(10px); }
}

@keyframes bounceOutDown {
  20% {
    transform: translateY(-20px);
  }
  50%, 55% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(-1000px);
  }
}

.pulse-effect {
  width: 80px;
  height: 80px;
  background-color: #FB440E;
  border-radius: 80%;
  animation: pulse 1.5s infinite;
  opacity: 0;  /* Start completely transparent */
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);  /* Start a bit smaller */
  z-index: 0;  /* Below .circle-btn */
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.4);  /* Grow even larger */
    opacity: 0;  /* Completely fade out */
  }
}



.approved {
  animation: bounceOutDown 0.6s forwards;
}
.ok-text {
    font-size: 20px;
    font-weight: bold;
    color: white;
    font-family: Arial, sans-serif; /* Arial is a commonly-used sans-serif font */
}


.circle-btn.success {
    border-color: #2CAF50;
    background-color: #2CAF50;
}

.swim-icon.success {
    fill: white; /* Assuming the SVG icon will change color based on its fill property */
}


.incorrect-password {
    animation: shake 0.8s;
    animation-fill-mode: forwards;
}


.top {
  height: 80.27px;
  min-width: 375px;
  position: relative;
}

.line-2 {
  height: 1px;
  left: 84px;
  position: absolute;
  top: 112px;
  width: 47px;
}

.filterYearBadelogg{
  margin-bottom:10px;
}

.profile-branding {
  align-items: center;
  display: flex;
  gap: 14px;
  padding: 8px 0px 15px 15px;
  position: relative;

  width: 375px;
}

.flip-container {
    perspective: 1000px;
    width: 80px;
    height: 80px;
}
/* .flip-container:hover .flipper, .flip-container.hover .flipper {
    transform: rotateY(180deg);
} */

.flipped {
    transform: rotateY(180deg);
}

.flip-container, .front, .back {
    width: 80px;
    height: 80px;
}
.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}
.front, .back {
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
}
.front {
    z-index: 2;
    transform: rotateY(0deg);
}
.back {
    transform: rotateY(180deg);
}
.bilde2 {
    height: 80px;
    min-width: 80px;
    object-fit: cover;
    border-radius: 50%; /* Makes the image circular */
}
.back {
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;  
    padding: 5px;
    position: relative; 
}

.content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.circle-btn {
    position: relative;  /* Ensure this is set so z-index and absolute positioning of child works */
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 5px solid #FB440E;
    border-radius: 50%;
    width: 80px; 
    height: 80px; 
    z-index: 1;
}


.swim-icon {
  width:40px;
  padding-bottom:3px;
}

  .pin-input-container {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -20%;      
      left: 50%;         
      transform: translateX(-50%);  
      margin-bottom: 10px;  
      z-index: 2;       
  }

  .pin-input-container input {
       display: flex;
      justify-content: center;
      width: 80px;
      padding: 5px;
      border-radius: 4px;
      border: 1px solid #FB440E;
      background-color: white;
      text-align: center;
      font-size: 16px;
  }
  .pin-input-container input:focus {

      border: 2px solid #FB440E;  /* This sets the focus border to the color you previously mentioned */
      outline: none;  /* This removes the default browser outline */
  }
.flip-container button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    /* Add other styles for the button as needed */
}

.frame-623 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 45px;
  position: relative;
  width: 239px;
}

.title2 {
  align-self: stretch;
  color: var(--vermilion);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 31.2px;
  letter-spacing: 0;
  line-height: 31.2px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.badebelly-siden-210622 {
  align-self: stretch;
  color: var(--black);
  letter-spacing: 0;
  line-height: 13.0px;
  margin-bottom: -2.00px;
  position: relative;
  white-space: nowrap;

}

.totals-year {
  align-items: center;
  display: flex;
  gap: 0px;
  padding: 0px 0px 0px 18px;
  position: relative;
  width: 375px;
}

.poeng-total {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  position: relative;
  width: fit-content;
  overflow: visible;
}

.streakLongest {
  display: flex;
  padding: 0px 0px 10px 18px;
  position: relative;
  width: 375px;
  gap: 0px;
  overflow: visible;
}

.badStat{
  margin-top:-10px;
  margin-bottom:10px;
}

.ng-2023 {
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  
}
.pl-2023 {
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  padding-left:10px;
}


.number2 {
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  text-align: left;
  margin-right:15px;
  overflow: visible;
}

.text-social{
   letter-spacing: 0;
  line-height: 18.2px;
  margin-top: 10px;
  position: relative;
  text-align: left;
  width: 286px;
}


.header {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  padding: 0px 0px 10px 18px;
  position: relative;
  width: 375px;
}


.headerStatistikk {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px 0px 0px 18px;
  position: relative;
  width: 375px;
}


.badelogg {
  align-self: stretch;
  color: var(--black);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-l2);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 31.2px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.baths-year {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}

.group-29 {
  height: 13px;
  min-width: 364px;
  position: relative;
}

.dato2 {
  letter-spacing: 0;
  line-height: 13.0px;
  margin-left: 20px;
  min-height: 16px;
  width: 49px;
}

.kl {
  letter-spacing: 0;
  line-height: 13.0px;
  margin-left: 36px;
  min-height: 16px;
  width: 49px;
}

.vann {
  letter-spacing: 0;
  line-height: 13.0px;
  margin-left: 18px;
  min-height: 16px;
  width: 50px;
}

.luft {
  letter-spacing: 0;
  line-height: 13.0px;
  margin-left: 21px;
  min-height: 16px;
  width: 30px;
}

.poeng {
  letter-spacing: 0;
  line-height: 13.0px;
  padding-left:50px;
  min-height: 16px;
}

.line-5 {
  height: 1px;
  margin-top: 5px;
  min-width: 375px;
  position: relative;
}
.share {
  align-items: flex-start;
  display: flex;
  padding: 0px 0px 0px 18px;
  position: relative;
  width: 375px;
}

.share-item {
}

.overlap-group2 {
  height: 33px;
  margin-top: -10px;
  margin-bottom:10px;
  width: 158px;
  padding-left:20px;

}

.skrytebilde {
  display: flex;
  justify-content: start;  /* align the children (buttons) to the start */
  align-items: center;     /* vertically center the children if needed */
  gap: 20px;  
  height: 45px;
  margin-bottom:-10px;
  position: relative;
  align-content: left;
  width:375px;

}

.overlap-group-skrytebilde {
  border: 1px solid;
  border-color: var(--vermilion);
  border-radius: 4px;
  display: flex;
  height: 32px;
  width: 156px;
  padding: 0 1px;
  cursor: pointer;
  margin-left:18px;
  margin-top:-28px;
}

.overlap-group-badges{
  border: 1px solid;
  border-color: var(--vermilion);
  border-radius: 4px;
  display: flex;
  height: 32px;
  width: 156px;
  padding: 0 1px;
  cursor: pointer;
  margin-left:0px;
  margin-top:-28px;
}

.camera-icon {
  align-self: center;
  height: 20px;
  margin-bottom: 3px;
  width: 25px;
  margin-left:4px;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lag-skrytebilde {
  height: 30px;
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -0.27px;
  text-align: center;
  width: 123px;
}


.x4u585 {
  align-items: flex-start;
  background-color: #E84822;
  border: 1px none;
  display: flex;
  height: 510px;
  overflow: hidden;
  padding: 0px 0;
  width: 400px;
  position: absolute;
  top: -9999px; 
  left: -9999px;
}

.overlap-group1-social {
  height: 466px;
  margin-left: -187px;
  margin-top: 15px;
  position: relative;
  width: 566px;
}

.overlap-group-social {
  height: 460px;
  left: 0;
  position: absolute;
  top: 0;
  width: 566px;
}

.flag {
  height: 77px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 383px;
  width: 317px;
}


.userglory {
  height: 402px;
  left: 215px;
  object-fit: cover;
  position: absolute;
  top: 10;
  box-sizing: border-box;
  position: absolute;
  width: 344px;
  height: 386px;
  border: 9px solid #FFAC01;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
}



.rectangle-1 {
  background: linear-gradient(180deg, rgb(255, 241, 131) 0%, rgb(248, 170, 25) 57.29%, rgb(255, 241, 131) 74.48%);
  border: 4px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(255, 196, 1), rgb(255, 241, 131) 47.4%, rgb(255, 196, 1) 100%) 1;
  box-shadow: 0px 0px 4px #00000040;
  height: 131px;
  left: 241px;
  position: absolute;
  top: 293px;
  width: 289px;
}

.title3 {
  left: 245px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 306px;
  white-space: nowrap;
  width: 281px;
}

.score-poeng {
  left: 241px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 334px;
  white-space: nowrap;
  width: 281px;
}

.rank-plass {
  left: 245px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 363px;
  white-space: nowrap;
  width: 281px;
}

.badet-sist2 {
  left: 245px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 398px;
  width: 281px;
}

.happy-bellies-of-fjellstrand {
left: 298px;
  top: 437px;
}


/* Style for the modal container */
.modal-container {
  max-width: 100%;  
  overflow:hidden;
}


.modal-container img, 
.modal-container .text-social {
    display: block;
}
.modalLogo{
margin-bottom:10px;

}