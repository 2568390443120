.badebrikke-modal {
    text-decoration: none;
    text-align: left;
    padding-top: 10px;
    margin-bottom: 0px;
    line-height: 20px;
    z-index: 20;
}

.modalLogo{
	margin-bottom: 10px;
}

.badebrikke-modal a {
    text-decoration: underline;
    text-align: left;
    color: inherit; 
}

.badebrikke-modal a:hover {
  
    text-decoration: underline; 
}

.badebrikke-modal a:visited {
   
    color: inherit; /* Keeps the color the same as parent even if the link has been visited */
}

.modal-content hr {
    border-top: 1px solid #D3D3D3; 
    border-bottom: none; 
    margin: 10px 0px 10px 0; 
}
